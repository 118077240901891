$(function() {

    $('form .element').each( function() {

        var $element = $(this);
        var $text    = $('.text', $element);
        var $input   = $('input, textarea', $element);

        $input.focus( function() {
            $text.stop().animate({opacity:0}, 500);
        });

        $input.blur( function() {
            var text = $.trim($input.val());
            if (!text)
                $text.stop().animate({opacity:1}, 1000);
        });

        var text = $.trim($input.val());
        if (text)
            $text.css({opacity:0});
    });

    $('form input[type=hidden]').val(Math.random());

});
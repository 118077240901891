$(function() {


    var $body   = $('body');
    var $header = $('header');
    var $nav    = $('nav');
    var $main   = $('main');
    var $footer = $('footer');

        // css pixel sizes

    var breaks = {
        small:  641, //phone
        medium: 961, //tablet
                     //default
        wide:  1400  //wide
    };

    // menu opening for smaller devices
    (function() {

        var $svg = $('header .menu svg');
        var navOpened = false;
        var timeout;

        var ensureCorrectSize = function() {

            var navHeightBefore = $nav.outerHeight();

            $nav.css('height','inherit');

            var mainHeight     = $main.outerHeight();
            var navHeightAfter = $nav.outerHeight();

            if (navHeightAfter > mainHeight) {
                $main.css('height', navHeightAfter);
            } else {
                $nav.css('height', navHeightBefore);
            }
        };

        var navigationOpen = function() {
            navOpened = true;
            $svg.addClass('cross');
            $body.addClass('menu-opened');

            var height   = $main.outerHeight();
            $nav.css('height', height);

            timeout = setTimeout(ensureCorrectSize, 500);
        };

        var navigationClose = function() {
            navOpened = false;
            $svg.removeClass('cross');
            $body.removeClass('menu-opened');
            $nav.css('height','');

            clearTimeout(timeout);
        };

        $('header .menu a').click(function(event) {
            event.preventDefault();

            if (navOpened)
                navigationClose();
            else
                navigationOpen();

            return false;
        });
    }());

    // fix footer
    (function() {

        var fixFooter = function() {

            $main.css('minHeight','');
            //$nav.css('minHeight','');

            var win    = $(window).height();
            var header = $header.outerHeight();
            var nav    = $nav.outerHeight();
            var main   = $main.outerHeight();
            var footer = $footer.outerHeight();

            if (nav - header > main) {
                $main.css('minHeight', nav - header);
                main = nav - header
            }

            if (header + main + footer < win)
                $main.css('minHeight', win - footer - header);
        };

        fixFooter();
        setTimeout(fixFooter, 100);
        $(window).resize(fixFooter);

    }());

    // fixed scrolling menu and logo
    (function() {

        var $logo = $('.logo .image', $header);

        var fixLogo = function() {

            if ($body.hasClass('menu-opened'))
                return;

            var scrolled = $(window).scrollTop();
            var doc      = $(document).height();
            var nav      = $nav.outerHeight();
            var footer   = $footer.outerHeight();

            $logo.css('top', scrolled + 'px');

            if (nav + scrolled > doc - footer)
                $nav.css('top', -((nav + scrolled) - (doc - footer)));
            else
                $nav.css('top', '');
        };

        fixLogo();
        $(window).scroll(fixLogo);

    }());

    // slider
    (function() {

        var $sliders = $('.js-slider');

        $sliders.each( function() {
            var $slider  = $(this);
            var $slides  = $('.js-slide', $slider);
            var $bullets = $('.js-bullets', $slider);
            var current  = 0;
            var count    = $slides.length;
            var interval = $slider.data('interval');
            var timeout;
            if (typeof interval === 'undefined')
                interval = 10;

            $bullets.each(function() {
                var $this = $(this);
                for (var idx = 0; idx < count; idx++) {
                    var $bullet = $('<div>');
                    $bullet.addClass('bullet');
                    $bullet.appendTo($this);
                    $bullet.click(function() {
                        var idx = $(this).index();
                        clearTimeout(timeout);
                        showSlide(idx);
                    });
                }
            });

            var nextSlide = function() {
                var next = current + 1;
                if (next >= count)
                    next = 0;

                showSlide(next);
            };

            var showSlide = function(index) {
                current = index;

                var $prevSlide = $('.js-slide.current', $slider);
                var $nextSlide = $($slides[index]);

                $slides.removeClass('prev');
                $prevSlide.removeClass('current');
                $prevSlide.addClass('prev');
                $nextSlide.addClass('current');

                var $bullets = $('.js-bullets .bullet', $slider);
                $bullets.removeClass('current');
                $($bullets[index]).addClass('current');

                timeout = setTimeout(nextSlide, interval * 1000);
            };

            showSlide(0);
        });

    }());

    //show image effect
    (function() {

        function showImages($elements) {
            var windowHeight = $(window).height();
            $($elements).each(function(){
                var thisPos = $(this).offset().top;

                var topOfWindow = $(window).scrollTop();
                if (topOfWindow + windowHeight - 200 > thisPos ) {
                    $(this).addClass("fadeIn");
                }
            });
        }

        // if the image in the window of browser when the page is loaded, show that image
        $(document).ready(function(){
            showImages('.fadeEffect');
        });

        // if the image in the window of browser when scrolling the page, show that image
        $(window).scroll(function() {
            showImages('.fadeEffect');
        });

    }());

    //lightbox
    (function() {
        lightbox.option({
            'alwaysShowNavOnTouchDevices': true
        })
    }());

    //on load effect
    (function() {

        $('.walls-categories .category').addClass('effect-loaded');

    }());

});